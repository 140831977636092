// AnalyticsContext.js
import React from "react";
import TrackedEvents from "./TrackedEvents";

const { REACT_APP_POSTHOG_URL } = process.env;
const { REACT_APP_POSTHOG_API_KEY } = process.env;

const apiKey = REACT_APP_POSTHOG_API_KEY;
const options = {
	api_host: REACT_APP_POSTHOG_URL,
};

const AnalyticsContext = React.createContext(
	new TrackedEvents(apiKey, options)
);

export default AnalyticsContext;
