import { createSlice } from "@reduxjs/toolkit";
import { API } from "../../../utils/axios/index";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import { requestStart, requestComplete, requestFail } from "../global/global";
import { formatRoles } from "../../../utils/helpers/apiHelpers";
import { firebaseobj } from "../../../components/auth/components/FirebaseInitialize";

const initialState = {
	token: null,
	userName: "",
	roleActions: [],
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		isAuthenticated(state, action) {
			const { token, user_name } = action.payload;
			state.token = token;
			state.userName = user_name;
		},
		setRoleActions(state, action) {
			state.roleActions = action.payload;
		},
	},
});

export const { isAuthenticated, setRoleActions } = authSlice.actions;
export default authSlice.reducer;

export const signIn = (payload) => (dispatch, getState) => {
	dispatch(requestStart());
	API.post("/login", payload)
		.then((response) => {
			if (response.status === 200) {
				const { data, user_id } = response.data;
				localStorage.setItem("userId", user_id);
				localStorage.setItem("token", data.token);
				localStorage.setItem("user", data.user_name);
				localStorage.setItem("userRoles", data.user_actions);
				const roles = formatRoles(data.user_actions);
				dispatch(setRoleActions(roles));
				dispatch(isAuthenticated(data));
				dispatch(requestComplete());
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const signOut = () => (dispatch) => {
	dispatch(requestStart());
	API.post("/logout")
		.then((response) => {
			if (response.status === 200) {
				localStorage.clear();
				sessionStorage.clear();
				// Check if SSO user. Logout of session
				if (firebaseobj?.auth().currentUser) {
					firebaseobj.auth().signOut();
				}

				dispatch(requestComplete());
				window.location.href = "/login";
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const samlLoginCallback = (payload) => (dispatch, getState) => {
	dispatch(requestStart());
	API.post("/samlCustomLogin", payload)
		.then((response) => {
			if (response.status === 200) {
				const { data, user_id } = response.data;
				localStorage.setItem("userId", user_id);
				localStorage.setItem("token", data.token);
				localStorage.setItem("user", data.user_name);
				localStorage.setItem("userRoles", data.user_actions);
				const roles = formatRoles(data.user_actions);
				dispatch(setRoleActions(roles));
				dispatch(isAuthenticated(data));
				dispatch(requestComplete());
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};
