import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { saveAs } from "file-saver";
import { API, ExcelAPI } from "../../../utils/axios/index";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import {
	reportingMetricDataFormatter,
	waterfallMetricDataFormatter,
	offerPerformanceMetricDataFormatter,
} from "../../../utils/helpers/reportingHelper";
import {
	requestStart,
	requestComplete,
	requestFail,
	toastError,
	requestCompleteNoLoader,
} from "../global/global";

const initialState = {
	reportingMetrics: {},
	waterfallMetrics: {},
	offerPerformanceMetrics: {},
};

const reportingSlice = createSlice({
	name: "event",
	initialState,
	reducers: {
		setReportingMetrics(state, action) {
			state.reportingMetrics = action.payload;
		},
		setWaterfallMetrics(state, action) {
			state.waterfallMetrics = action.payload;
		},
		setOfferPerformanceMetrics(state, action) {
			state.offerPerformanceMetrics = action.payload;
		},
	},
});

export const {
	setReportingMetrics,
	setWaterfallMetrics,
	setOfferPerformanceMetrics,
} = reportingSlice.actions;
export default reportingSlice.reducer;

export const getModelData =
	({ payload, dataType, elementLabels }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.post(`/model-data`, payload)
			.then((response) => {
				if (
					response.data &&
					(response.data.status === 200 ||
						response.data.status === true)
				) {
					const { data } = response.data;

					// If Using to fetch aggregate metrics
					if (dataType === "reportingMetrics") {
						const reportingMetricData =
							reportingMetricDataFormatter(
								data,
								payload.parameters.aggregation
							);
						const reportingMetricsTemp = _.cloneDeep(
							getState().reports.reportingMetrics
						);
						dispatch(
							setReportingMetrics({
								...reportingMetricsTemp,
								[payload.parameters.aggregation]:
									reportingMetricData,
							})
						);
					} else if (dataType === "waterfallMetrics") {
						const {
							revenueWaterfallMetricData,
							marginWaterfallMetricData,
						} = waterfallMetricDataFormatter(data, elementLabels);
						dispatch(
							setWaterfallMetrics({
								margin: marginWaterfallMetricData,
								revenue: revenueWaterfallMetricData,
							})
						);
					} else if (dataType === "offerPerformanceMetrics") {
						const { topOfferMetricData, bottomOfferMetricData } =
							offerPerformanceMetricDataFormatter(data);
						dispatch(
							setOfferPerformanceMetrics({
								top: topOfferMetricData,
								bottom: bottomOfferMetricData,
							})
						);
					}
					dispatch(requestComplete());
					return data || {};
				} else {
					dispatch(requestFail());
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
			});
	};

export const getModelDataDownload =
	({ payload, dataType, elementLabels }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		ExcelAPI.post(`/reports/download`, payload)
			.then((response) => {
				// const blob = new Blob([response.data], {
				// 	type: "text/csv;charset=utf-8",
				// });
				const blob = new Blob([response.data], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
				});
				saveAs(blob, payload.report_name);
				dispatch(requestComplete());
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
			});
	};

export const downloadFlexiTimeSkuLevelReport =
	(payload) => (dispatch, getState) => {
		dispatch(requestStart());
		API.post(`/reports/sap/extract`, payload)
			.then((response) => {
				dispatch(
					requestComplete(
						"Report download will take a few minutes. You will be notified once it is completed."
					)
				);
				return response.data;
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
			});
	};

export const downloadSapReport = (payload) => (dispatch, getState) => {
	dispatch(requestStart());
	ExcelAPI.post(`/reports/sap/extract`, payload)
		.then((response) => {
			// if(payload?.parameters?.report_name === 'sap_extract' || payload?.parameters?.report_name === 'sap_sku_level_report'){
			// const blob = new Blob([response.data], {
			// 	type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
			// });
			// saveAs(blob, payload?.parameters?.report_name);
			// dispatch(requestComplete());
			// } else {
			dispatch(requestComplete());

			return response.data;
			// }
		})
		.catch((error) => {
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const downloadReports = (payload) => (dispatch, getState) => {
	dispatch(requestStart());
	ExcelAPI.get(`/downloaded-report?download_report_id=${payload.id}`)
		.then((response) => {
			const blob = new Blob([response.data], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
			});
			saveAs(blob, payload?.parameters?.report_name);
			dispatch(requestComplete());
		})
		.catch((error) => {
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};
