export const reportingMetricDataFormatter = (metricData, aggregation) => {
	const formattedData = [];
	metricData.forEach((rowData) => {
		const tempData = {
			...rowData,
		};
		tempData.total = "Entire Chain";
		tempData.underperforming_percentage =
			rowData.underperforming_percentage &&
			rowData.underperforming_percentage != 0
				? rowData.underperforming_percentage * 100
				: rowData.underperforming_percentage;

		tempData.incremental_revenue_percentage =
			rowData.incremental_revenue_percentage &&
			rowData.incremental_revenue_percentage != 0
				? rowData.incremental_revenue_percentage * 100
				: rowData.incremental_revenue_percentage;

		tempData.incremental_margin_percentage =
			rowData.incremental_margin_percentage &&
			rowData.incremental_margin_percentage != 0
				? rowData.incremental_margin_percentage * 100
				: rowData.incremental_margin_percentage;

		tempData.incremental_sales_units_percentage =
			rowData.incremental_sales_units_percentage &&
			rowData.incremental_sales_units_percentage != 0
				? rowData.incremental_sales_units_percentage * 100
				: rowData.incremental_sales_units_percentage;
		formattedData.push(tempData);
	});
	return formattedData;
};

export const waterfallMetricDataFormatter = (metricData, elementLabels) => {
	const revenueWaterfallMetricData = [];
	const marginWaterfallMetricData = [];
	const marginMetrics = [
		"baseline_margin",
		"margin_positive",
		"margin_negative",
	];
	const revenueMetrics = [
		"baseline_revenue",
		"revenue_positive",
		"revenue_negative",
	];
	metricData.forEach((rowData) => {
		revenueMetrics.forEach((rm) => {
			const revenueData = {
				name: elementLabels[rm],
				y: rowData[rm],
				color: rm == "revenue_positive"? "#BAD376" : rm == "revenue_negative" ? "#7A4460" : "#95B8F2"
			};
			revenueWaterfallMetricData.push(revenueData);
		});
		revenueWaterfallMetricData.push({
			isSum: true,
			name: "Net Revenue",
		});

		marginMetrics.forEach((mm) => {
			const marginData = {
				name: elementLabels[mm],
				y: rowData[mm],
				color: mm == "margin_positive"? "#BAD376" : mm == "margin_negative" ? "#7A4460" : "#95B8F2"
			};
			marginWaterfallMetricData.push(marginData);
		});
		marginWaterfallMetricData.push({
			isSum: true,
			name: "Net Margin",
		});
	});

	return {
		revenueWaterfallMetricData,
		marginWaterfallMetricData,
	};
};

export const offerPerformanceMetricDataFormatter = (metricData) => {
	const topOfferMetricData = [];
	const bottomOfferMetricData = [];

	metricData[0]?.top10.forEach((rowData) => {
		const topData = {
			...rowData,
		};
		topOfferMetricData.push(topData);
	});

	metricData[0]?.bottom10.forEach((rowData) => {
		const bottomData = {
			...rowData,
		};
		bottomOfferMetricData.push(bottomData);
	});

	return {
		topOfferMetricData,
		bottomOfferMetricData,
	};
};
