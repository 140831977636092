import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import _ from "lodash";
import SideBar from "../sidebar/SideBar";
import Header from "../header/Header";
import PolicyIcon from "@mui/icons-material/Policy";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";

import "./Layout.scss";

const Layout = (props) => {
	const isAuthenticated = localStorage.getItem("token") || null;
	if (!isAuthenticated) {
		// return <Navigate to="/login" replace />;
	}

	const getIcon = (screen) => {
		let icon = null;
		switch (screen) {
			case "decision_dashboard":
				icon = React.createElement(WidgetsOutlinedIcon);
				break;
			case "marketing-calendar":
				icon = React.createElement(CalendarMonthIcon);
				break;
			case "workbench":
				icon = React.createElement(ListAltOutlinedIcon);
				break;
			case "reporting":
				icon = React.createElement(AssessmentOutlinedIcon);
				break;
			default:
				icon = React.createElement(PolicyIcon);
				break;
		}
		return icon;
	};

	let sideBarOptions = [];

	if (props.navConfig && !_.isEmpty(props.navConfig)) {
		props.navConfig.forEach((screen) => {
			const option = {
				link: screen.link,
				title: screen.title,
				order: screen.order,
				icon: getIcon(screen.key),
			};
			sideBarOptions.push(option);
		});
	}

	return (
		<>
			<div className="wrapper">
				<SideBar options={sideBarOptions} />
				<div className="layout">
					<Header title="Price Smart" />
					<div className="main-content" id="content">
						{props.children}
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (store) => {
	return {
		navConfig: store.global.navConfig,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
