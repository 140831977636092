import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	customVariables: {
		closedNavWidth: "4.5rem",
		navWidth: "20.75rem",
		commentDrawerWidth: 50,
	},
	typography: {
		fontFamily: "Poppins",
		allVariants: {
			fontSize: "0.8rem",
		},
	},
	palette: {
		primary: {
			main: "rgb(0, 85, 175)",
		},
		secondary: {
			main: "#e0e0e0",
		},
	},
	components: {
		MuiDrawer: {
			styleOverrides: {
				root: {
					flexShrink: "0",
					whiteSpace: "nowrap",
				},
				paper: {
					background: "#091523",
					transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					paddingLeft: "0",
					paddingRight: "0",
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: "unset",
					minWidth: "40px",
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {},
				checked: {},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					marginBottom: "20px",
				},
				indicator: {
					// backgroundColor: "#4f677b",
				},
				MuiTab: {
					root: {
						minWidth: "160px",
						padding: "6px 12px",
						fontSize: "0.85rem",
						fontWeight: "600",
						overflow: "hidden",
						position: "relative",
					},
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "capitalize",
					letterSpacing: "0.03rem",
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				root: {},
				paper: {
					maxWidth: "430px",
					borderRadius: "10px 10px 6px 6px",
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					padding: "25px",
				},
			},
		},
		MuiDialogActions: {
			background: "#d3d3d345",
			padding: "15px",
		},
		TextField: {
			styleOverrides: {
				root: {
					maxWidth: "100%",
					minWidth: "100%",
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					cursor: "pointer",
					color: "#5295e1",
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					marginLeft: "10px",
					marginRight: "10px",
					height: "34px",
					textTransform: "capitalize",
					textAlign: "left",
					fontSize: "14px",
					lineHeight:"21px",
					fontWeight:"400",
					letterSpacing: "0px",
					padding: "8px 20px",
					// fontSize: "0.9rem",
				},
				outlined: {
					border: "none",
					"&:hover": {
						border: "none",
						background: "transparent",
					},
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					margin: "6px 0",
					borderColor: "rgba(0, 0, 0, 0.12)",
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					paddingTop: "15px",
					paddingBottom: "15px",
				},
			},
		},
	},
});

export default theme;
