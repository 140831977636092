import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import SideBar from "../sidebar/SideBar";
import Header from "../header/Header";

import "./Layout.scss";

const LayoutLogin = (props) => {
	const isAuthenticated = localStorage.getItem("token") || null;
	if (!isAuthenticated) {
		// return <Navigate to="/login" replace />;
	}

	let sideBarOptions = [];
	return (
		<>
			<div className="wrapper">
				<SideBar options={sideBarOptions} />
				<div className="layout">
					<Header title="Price Smart" />
					<div className="main-content" id="content">
						{props.children}
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (store) => {
	return {
		navConfig: store.global.navConfig,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutLogin);
