import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { saveAs } from "file-saver";

import { API, ExcelAPI } from "../../../utils/axios/index";
import { eventListFormatter } from "../../../utils/helpers/eventHelper";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import {
	requestStart,
	requestComplete,
	requestFail,
	toastError,
	requestCompleteNoLoader,
} from "../global/global";
import { setFilteredData } from "../filters/filters";

const initialState = {
	eventsList: {},
	eventDetails: {},
	activeEventId: null,
	storesList: [],
};

const eventSlice = createSlice({
	name: "event",
	initialState,
	reducers: {
		setEventList(state, action) {
			state.eventsList = action.payload;
		},
		setEventDetails(state, action) {
			state.eventDetails = action.payload;
		},
		setActiveEventId(state, action) {
			state.activeEventId = action.payload;
		},
		setStoresList(state, action) {
			state.storesList = action.payload;
		},
	},
});

export const {
	setEventList,
	setEventDetails,
	setActiveEventId,
	setStoresList,
} = eventSlice.actions;
export default eventSlice.reducer;

export const getEvents =
	({
		payload,
		dateRange = "ty",
		shouldSaveFilters = false,
		filterName,
		from,
	}) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.post("/get-events", payload)
			.then((response) => {
				if (response.status === 200) {
					if (response.data) {
						if (_.isEmpty(response.data?.data)) {
							dispatch(
								toastError(
									"There is no data for the selected filters"
								)
							);
						}
						const { data } = response.data;
						dispatch(
							setEventList({
								...getState().event.eventsList,
								[dateRange]: data,
							})
						);
						if (shouldSaveFilters) {
							const eventsFilterData = eventListFormatter(data);
							const filtersDataPayload = {
								payload: eventsFilterData || [],
								filterName,
								dataType: "options",
								activeScreen: from,
							};
							dispatch(setFilteredData(filtersDataPayload));
						}
						dispatch(requestComplete());
					}
				}
			})
			.catch((error) => {
				console.log(error);
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));

				dispatch(
					setEventList({
						...getState().event.eventsList,
						[dateRange]: [],
					})
				);
				if (shouldSaveFilters) {
					const eventsFilterData = eventListFormatter([]);
					const filtersDataPayload = {
						payload: eventsFilterData || [],
						filterName,
						dataType: "options",
						activeScreen: from,
					};
					dispatch(setFilteredData(filtersDataPayload));
				}
			});
	};

export const createEvent = (payload) => (dispatch, getState) => {
	dispatch(requestStart());
	return API.post("/events", payload)
		.then((response) => {
			if (response.status === 200) {
				const { data, message } = response.data;
				// dispatch(setEventList(data));

				dispatch(requestComplete(message));

				return data;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const updateEventDetails = (payload) => (dispatch) => {
	dispatch(setEventDetails(payload));
};

export const getEventDetails = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.post("/events-details", payload)
		.then((response) => {
			if (response.status === 200) {
				const { data } = response.data;
				dispatch(setEventDetails(data));
				dispatch(requestComplete());
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const editEvent = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.put("/events", payload)
		.then((response) => {
			if (response.status === 200) {
				const { data, message } = response.data;
				// dispatch(setEventDetails(data));
				dispatch(requestComplete(message));

				return data;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const deleteEvent = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.post("/events/delete", payload)
		.then((response) => {
			if (response.status === 200) {
				const { message } = response.data;
				dispatch(requestComplete(message));
				return true;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const resimulateEvents = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.post("/bulk-resimulate", payload)
		.then((response) => {
			if (response.status === 200) {
				const { message } = response.data;
				dispatch(requestComplete(message));
				return true;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const lockEvent = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.put("/events/lock", payload)
		.then((response) => {
			if (response.status === 200) {
				const { data, message } = response.data;
				// dispatch(setEventDetails(data));
				dispatch(requestComplete(message));

				return data;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const downloadEventsTableData = (payload) => (dispatch) => {
	dispatch(requestStart());
	return ExcelAPI.post("/reports/get-events/download", payload)
		.then((response) => {
			// const blob = new Blob([response.data], {
			// 	type: "text/csv;charset=utf-8",
			// });
			const blob = new Blob([response.data], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
			});
			saveAs(blob, payload.report_name);
			dispatch(requestComplete());
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const getCopiedEventDetails = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.post("/events-details", payload)
		.then((response) => {
			if (response.status === 200) {
				const { data } = response.data;
				dispatch(requestComplete());
				return data;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const uploadStore = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.post("/store-details", payload)
		.then((response) => {
			if (response.status === 200) {
				const { data } = response.data;
				dispatch(requestComplete());
				return data;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const getStoreData = (payload) => (dispatch, getState) => {
	dispatch(requestStart());
	return API.post(`/filters`, payload)
		.then((response) => {
			if (response.data && response.data.status === true) {
				let data = _.cloneDeep(response.data.data);
				dispatch(requestComplete());
				dispatch(setStoresList(data));
				return data;
			}
		})
		.catch((error) => {
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const validateEventEdit = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.post("/events/edit/validate", payload)
		.then((response) => {
			if (response.status === 200) {
				const { data } = response.data;
				dispatch(requestComplete());

				return data[0];
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const downloadValidationData = (payload) => (dispatch) => {
	dispatch(requestStart());
	ExcelAPI.post("/events/edit/validate/download", payload)
		.then((response) => {
			// const blob = new Blob([response.data], {
			// 	type: "text/csv;charset=utf-8",
			// });
			const blob = new Blob([response.data], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
			});
			saveAs(blob, payload.report_name);
			dispatch(requestComplete());
		})
		.catch((error) => {
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};
