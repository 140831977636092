/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-useless-escape */
import React from "react";
import moment from "moment";
import _ from "lodash";

export const setErrorMessage = (error) => {
	let errorMessage = "Something went wrong. Try again";
	if (error.response) {
		const { data } = error.response;
		errorMessage = data && data.message ? data.message : errorMessage;
	}
	if (error.response && error.response.status === 404) {
		errorMessage = "We couldn't find what you're looking for";
	}
	if (error.response && error.response.status === 418) {
		errorMessage =
			"A new version of our application is now available. Please clear the browser cache and reload the application to access the latest version.";
	}
	return errorMessage;
};

export const toBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			return resolve({
				fileName: file.name,
				base64: reader.result,
			});
		};
		reader.onerror = (error) => {
			console.log(error);
			return reject(error);
		};
	});
};

export const isArrayEmpty = (data = []) => {
	if (!data) return true;
	if (data && data.length === 0) return true;
	return false;
};

export const isArrayEqual = (array1, array2) => {
	let isEqual = false;
	if (array1 && array2 && array1.length === array2.length) {
		isEqual = array1.every((element, index) => {
			return element === array2[index];
		});
	}
	return isEqual;
};

export const arrayHasDuplicates = (array1) => {
	const array2 = array1.map((v) => v.toLowerCase());
	return array2.some((val, i) => array2.indexOf(val.toLowerCase()) !== i);
};

export const uniqueArray = (array1) => {
	return array1.filter((value, index, self) => {
		return self.indexOf(value) === index;
	});
};

export const htmlEncode = (text) => {
	const charMapping = {
		"&": "&amp;",
		"<": "&lt;",
		">": "&gt;",
		'"': "&quot;",
		"'": "&#039;",
		",": "&comma;",
	};
	return text.replace(/[&<>"',]/g, (m) => {
		return charMapping[m];
	});
};

export const htmlDecode = (text) => {
	const doc = new DOMParser().parseFromString(text, "text/html");
	return doc.documentElement.textContent;
};

export const htmlTagDecode = (text) => {
	return <span dangerouslySetInnerHTML={{ __html: text }} />;
};

export const dateFormatter = (date, format) => {
	switch (format) {
		case "hh:mm A":
			return moment(date).format("hh:mm A");
		default:
			return moment(date, "MM-DD-YYYY");
	}
};

export const getMonth = (date) => {
	const newDate = moment(date, "MM/DD/YYYY");
	const monthName = newDate.format("MMMM");
	const monthId = newDate.format("M");
	return {
		monthName,
		monthId,
	};
};

export const getYear = (date) => {
	const newDate = moment(date, "MM/DD/YYYY");
	return newDate.format("YYYY");
};

export const formatThousandSeparators = (num) => {
	const number = Math.round(num * 100) / 100;
	const numParts = number.toString().split(".");
	numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return numParts.join(".");
};

export const base64Regex = new RegExp(
	"^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$"
);

export const isArrayMissingItems = (array1, array2) => {
	const missingItems = _.difference(array1, array2);
	if (missingItems && missingItems.length) {
		return true;
	}
	return false;
};

export const isArrayOfObjectsMissingItems = (array1, array2, key) => {
	const missingItems = _.differenceBy(array1, array2, key);
	if (missingItems && missingItems.length) {
		return true;
	}
	return false;
};

export const compareObjectsByKey = (array1, array2, key) => {
	const formattedArray1 = _.uniq(array1.map((a) => a[key]));
	const formattedArray2 = _.uniq(array2.map((a) => a[key]));

	let isEqual = false;
	isEqual = formattedArray2.every((element, index) => {
		return formattedArray1.includes(element);
	});
	return isEqual;
};
