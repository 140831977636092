import React, { Suspense, lazy } from "react";
import { useContext, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
	Routes,
	Route,
	BrowserRouter as Router,
	redirect,
} from "react-router-dom";
import { Skeleton } from "@mui/material";
import { toastError } from "../../store/features/global/global";
import { setRoleActions } from "../../store/features/auth/auth";

/* 404 Page */
import NotFound from "./NotFound";

/* loader and alerts */
import LoadingOverlay from "../../components/ui/loader/Loader";
import { LoadingOverlayBrew } from "../../components/ui/loader/LoaderBrew";
import SnackBar from "../../components/ui/snackbar/Snackbar";

/* Layout */
import Layout from "../../components/common/layout/Layout";

/* Components */
import Login from "../../components/auth/Login";

import { formatRoles } from "../../utils/helpers/apiHelpers";
import LayoutLogin from "../../components/common/layout/Layout_login";
import AnalyticsContext from "../../posthog/AnalyticsContext";
import { createBrowserHistory } from "history";

const userId = localStorage.getItem("userId");
const user_name = localStorage.getItem("user");
const history = createBrowserHistory();
const userInfo = {
	userId: userId,
	properties: {
		name: user_name,
		email: userId,
	},
};

/* Decision Dashboard */
const DecisionDashboard = lazy(() =>
	import("../../components/decisionDashboard/DecisionDashboard")
);

/* Marketing Calendar */
const MarketingCalendar = lazy(() =>
	import("../../components/marketingCalendar/MarketingCalendar")
);

const CreateEvent = lazy(() =>
	import("../../components/marketingCalendar/createEvent/CreateEvent")
);
/* Workbench */
const PromoWorkbench = lazy(() =>
	import("../../components/promoWorkbench/PromoWorkbench")
);

const CreatePromo = lazy(() =>
	import("../../components/promoWorkbench/createPromo/CreatePromo")
);

/* Reporting */
const Reporting = lazy(() => import("../../components/reporting/Reporting"));

/*LoginScreen*/
const LoginScreen = lazy(() =>
	import("../../components/loginScreen/LoginScreen")
);

export const protectedRoutes = [
	{
		path: "/decision-dashboard",
		component: DecisionDashboard,
		layout: Layout,
		exact: true,
		isPublic: true,
		title: "Decision Dashboard",
		key: "decisionDashboard",
		breadcrumbPath: "decision-dashboard",
	},
	{
		path: "/marketing-calendar",
		component: MarketingCalendar,
		layout: Layout,
		exact: true,
		isPublic: true,
		title: "Calendar View",
		key: "marketingCalendar",
		breadcrumbPath: "marketing-calendar",
	},
	{
		path: "/marketing-calendar/create-event",
		component: CreateEvent,
		layout: Layout,
		exact: true,
		isPublic: true,
		title: "Event",
		key: "createEventApplicability",
		breadcrumbPath: "create-event",
	},
	{
		path: "/workbench",
		component: PromoWorkbench,
		layout: Layout,
		exact: true,
		isPublic: true,
		title: "Workbench",
		key: "workbench",
		breadcrumbPath: "workbench",
	},
	{
		path: "/workbench/create-promotion",
		component: CreatePromo,
		layout: Layout,
		exact: true,
		isPublic: true,
		title: "Promotion",
		key: "createPromoSelectProducts",
		breadcrumbPath: "create-promotion",
	},
	{
		path: "/reporting",
		component: Reporting,
		layout: Layout,
		exact: true,
		isPublic: true,
		title: "Reporting",
		key: "reporting",
		breadcrumbPath: "reporting",
	},
];

export const protectedLoginRoutes = [
	{
		path: "/pricesmart-home",
		component: LoginScreen,
		layout: LayoutLogin,
		exact: true,
		isPublic: true,
		title: "Home",
		key: "pricesmart-home",
		breadcrumbPath: "pricesmart-home",
	},
];

const AppRoutes = (props) => {
	const analytics = useContext(AnalyticsContext);

	useEffect(() => {
		const unlisten = history.listen((location) => {
			analytics.trackPageView(location.pathname, userInfo);
		});
		analytics.trackPageView(history.location.pathname, userInfo);

		return () => {
			unlisten();
		};
	}, [analytics, history]);

	return (
		<Router history={history}>
			<main className="App">
				<Suspense
					fallback={
						<div>
							<h1>...Loading</h1>
							<Skeleton />
						</div>
					}
				>
					<Routes>
						<Route exact path="/" element={<Login />} />
						<Route exact path="/login" element={<Login />} />

						{/* Login Routes for Promo and Markdown */}
						{protectedLoginRoutes.map((args) => {
							const {
								component: Component,
								layout: ProtectedLoginLayout,
								...rest
							} = args;
							if (props.isAuthenticated || 1) {
								const roles = localStorage.getItem("userRoles");
								if (roles && _.isEmpty(props.roleActions)) {
									const formattedRoles = formatRoles(
										roles.split(",")
									);
									props.setRoleActions(formattedRoles);
								}
								return (
									<Route
										exact
										{...rest}
										element={
											<Suspense>
												<ProtectedLoginLayout>
													<Component {...rest} />
												</ProtectedLoginLayout>
											</Suspense>
										}
									/>
								);
							} else {
								props.toastError("Please Login to continue...");
							}
						})}

						{/* Promo Routes */}

						{protectedRoutes.map((args) => {
							const {
								component: Component,
								layout: ProtectedLayout,
								...rest
							} = args;
							if (props.isAuthenticated || 1) {
								const roles = localStorage.getItem("userRoles");
								if (roles && _.isEmpty(props.roleActions)) {
									const formattedRoles = formatRoles(
										roles.split(",")
									);
									props.setRoleActions(formattedRoles);
								}
								return (
									<Route
										exact
										{...rest}
										element={
											<Suspense>
												<ProtectedLayout>
													<Component {...rest} />
												</ProtectedLayout>
											</Suspense>
										}
									/>
								);
							} else {
								props.toastError("Please Login to continue...");
							}
						})}
						<Route path="/*" element={<NotFound />} />
						<Route
							path="/under-maintenance"
							element={<NotFound type="under_maintenance" />}
						/>
					</Routes>
				</Suspense>
				<LoadingOverlay showLoader={props.loading !== 0} />
				<LoadingOverlayBrew showLoader={props.lengthyOpLoader} />
				{props.error && (
					<SnackBar message={props.error} alertType="error" />
				)}
				{props.message && (
					<SnackBar message={props.message} alertType="info" />
				)}
			</main>
		</Router>
	);
};

const mapStateToProps = (state) => ({
	loading: state.global.loading,
	lengthyOpLoader: state.global.lengthyOpLoader,
	error: state.global.error,
	message: state.global.message,
	isAuthenticated: state.auth.token,
	roleActions: state.auth.roleActions,
});

const mapDispatchToProps = (dispatch) => {
	return {
		toastError: (data) => dispatch(toastError(data)),
		setRoleActions: (data) => dispatch(setRoleActions(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
