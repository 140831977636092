import _ from "lodash";
import { ACTION_MAPPING } from "../../constants/Constants";

export const getLowestHierarchy = (filtersData) => {
	let lowestValidHierarchy = null;
	Object.keys(filtersData).forEach((hierarchy) => {
		if (
			!_.isEmpty(filtersData[hierarchy]) &&
			(!lowestValidHierarchy || lowestValidHierarchy < hierarchy)
		) {
			lowestValidHierarchy = hierarchy;
		}
	});
	return {
		[lowestValidHierarchy]: filtersData[lowestValidHierarchy],
	};
};

export const formatRoles = (roles) => {
	// const { roles } = data; //roles array conatainig role ids
	let rolesValues = [];
	if (roles) {
		roles.forEach((role) => {
			if (ACTION_MAPPING[role]) {
				rolesValues.push(ACTION_MAPPING[role]);
			}
		});
	}
	return rolesValues;
};

export const formatGlobalHierarchyOptions = (data) => {
	const adTypeOptions = [];
	const offerTypeOptions = {};
	const channelOptions = [];
	data.event_ad_type?.forEach((adType) => {
		if (adType.level === 1) {
			adTypeOptions.push({
				label: adType.name,
				value: adType.id,
				is_default: adType.is_default,
			});
		}
	});
	data.event_channel?.forEach((channel) => {
		if (channel.level === 1) {
			channelOptions.push({
				label: channel.name,
				value: channel.id,
				is_default: channel.is_default,
			});
		}
	});
	data.offer_type?.forEach((offerType) => {
		if (offerType.level === 1) {
			if (!offerTypeOptions[`offerType-${offerType.sub_parent}`]) {
				offerTypeOptions[`offerType-${offerType.sub_parent}`] = [];
			}
			offerTypeOptions[`offerType-${offerType.sub_parent}`].push({
				label: offerType.name,
				value: offerType.id,
				is_default: offerType.is_default,
			});
		}
	});

	return {
		adTypeOptions,
		channelOptions,
		offerTypeOptions,
	};
};
