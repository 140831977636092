import _, { map, cloneDeep, isEmpty, filter } from "lodash";
import moment from "moment";
import { getFiscalWeekArray } from "./calendarHelper";

export const getDataFromFiltersData = (name, data, key) => {
	return data[name] && data[name][key] ? data[name][key] : [];
};

export const getAllFiltersData = ({ data, list }) => {
	let newData = {};
	let productFilters = {};
	let storeFilters = {};
	let filterData = {};
	map(list, (item) => {
		if (
			item.type &&
			(item.type.includes("dateRange") ||
				item.type.includes("events") ||
				item.type.includes("promos"))
		) {
			filterData[item.type] = data[item.type];
		} else if (
			item.type &&
			(item.type.includes("adTypeOptions") ||
				item.type.includes("channelOptions"))
		) {
			filterData[item.type] = !isEmpty(data[item.type])
				? data[item.type].selectedItemsArray
				: [];
		} else if (item.filter_type && item.filter_type.includes("store")) {
			//channel_exclusive_flag
			storeFilters[item.type] = !isEmpty(data[item.type])
				? data[item.type].selectedItemsArray
				: [];
		} else
			productFilters[item.type] = !isEmpty(data[item.type])
				? data[item.type].selectedItemsArray
				: [];
	});
	newData["product_hierarchy"] = productFilters;
	newData["store_hierarchy"] = storeFilters;
	filterData.filters = newData;
	return filterData;
};

export const getFirstFilterName = (data) => {
	let array = Object.keys(data);
	return !isEmpty(array) ? array[0] : "";
};

export const resetFilterData = (data, retainArr, action) => {
	let newData = cloneDeep(data);
	map(Object.keys(newData), (key, i) => {
		newData[key] = {
			options: [],
			selectedItems: [],
			selectedItemsArray: [],
		};
	});
	return newData;
};

export const getFilterList = (data, from) => {
	let screenName = from;
	let dataClone = cloneDeep(data);
	let screenConfig = (dataClone && dataClone[screenName]) || [];
	let newFilterOptions = filter(
		screenConfig.filter_options,
		(item) => !item.hide_by_default //filter out the filters that ar hidden by default
	);
	screenConfig.filter_options = newFilterOptions;
	return screenConfig;
};

export const getUpdatedFilterListForSelectedItems = ({
	filterConfig,
	screenConfig,
	from,
	selectedItems,
}) => {
	//example filter_config
	// filter_config: {
	// 	channel: {
	// 		"Brick & Mortar": {
	// 			remove: ["bin"],
	// 		},
	// 		"E-Commerce": {
	// 			remove: ["item_group"],
	// 		},
	// 	},
	// },
	// add below line to filter object to hide the filter by default
	// hide_by_default: true,

	let newFiltersOptions =
		screenConfig[from] && screenConfig[from].filter_options;
	newFiltersOptions = cloneDeep(newFiltersOptions);
	const selectedItemsValues = map(selectedItems, (itm) => itm.value);
	//considering that selected items will alwasy have 1 item(i.e, single-select filter)
	const validations =
		filterConfig[selectedItemsValues && selectedItemsValues[0]];
	if (isEmpty(validations)) {
		//in case validations does not exist default filters
		return filter(newFiltersOptions, (item) => !item.hide_by_default);
	}

	for (let key in validations) {
		let value = validations[key];
		if (key === "remove") {
			// only remove case handled for now
			newFiltersOptions = filter(
				newFiltersOptions,
				(item) => !value.includes(item.type)
			);
		}
		//add extra validations here if needed. (ex: swap, add etc)
	}

	return newFiltersOptions;
};

export const getUpdatedFilters = ({ data, filtersList, callApi }) => {
	const { item_group, product_filters, store_filters } = filtersList || {};
	let filters = {
		...(item_group || {}),
		...(product_filters || {}),
		...(store_filters || {}),
	};
	let filtersData = {};

	map(data, (item) => {
		const key = item.type;
		let selectedItemsArray =
			key === "item_group"
				? filters["custom_item_group_id"]
				: filters[key];
		if (selectedItemsArray && !Array.isArray(selectedItemsArray)) {
			selectedItemsArray = [selectedItemsArray];
		}
		let options = map(selectedItemsArray, (o) => {
			return { value: o, label: o };
		});
		filtersData[key] = {
			selectedItemsArray: selectedItemsArray,
			selectedItems: options,
			options: options,
		};
		if (callApi) filtersData[key].callApi = true;
	});

	return filtersData;
};

export const invokeOnDatesChange = (
	sDate,
	eDate,
	daterangeOrWeek,
	fiscalCalendarDetails
) => {
	let data = getFiscalWeekArray(
		sDate,
		eDate,
		daterangeOrWeek,
		fiscalCalendarDetails
	);

	return {
		startDate: moment(sDate).format("YYYY-MM-DD"),
		endDate: moment(eDate).format("YYYY-MM-DD"),
		weeks: data.weekNumList,
	};
};

export const sortProductHierarchy = (data) => {
	const hierarchyOrder = [
		"product_h1",
		"product_h2",
		"product_h3",
		"product_h4",
		"brand",
		"product_h5",
	];
	const sortedData = {};
	hierarchyOrder.forEach((h) => {
		sortedData[h] = _.cloneDeep(data?.valid?.[h]);
	});
	return sortedData;
};

export const eventFilterFormatter = (eventList, otherFilterValues) => {
	let data = _.cloneDeep(eventList);
	const formattedData = [];
	!_.isEmpty(data) &&
		data.forEach((event) => {
			if (
				((!_.isEmpty(otherFilterValues?.adTypeOptions) &&
					otherFilterValues?.adTypeOptions?.includes(
						event.ad_type
					)) ||
					_.isEmpty(otherFilterValues?.adTypeOptions)) &&
				((!_.isEmpty(otherFilterValues?.channelOptions) &&
					otherFilterValues?.channelOptions?.includes(
						event.channel_type
					)) ||
					_.isEmpty(otherFilterValues?.channelOptions))
			) {
				const formattedEvent = {
					...event,
					event_id: event.event_id,
					event_name: event.event_name,
					label: event.event_name,
					value: event.event_id,
					start_date: event.start_date,
					end_date: event.end_date,
					event_promos: _.cloneDeep(event.event_promos),
				};
				formattedData.push(formattedEvent);
			}
		});
	return formattedData;
};

export const getUpdatedFilterListForPromotions = ({
	selectedItems,
	eventOptions,
}) => {
	const selectedEventIds = selectedItems.map((item) => item.value);
	// Filter event options on selected Items and return promos
	const selectedEvents = eventOptions.filter((event) =>
		selectedEventIds.includes(event.value)
	);
	const promoOptions = [];
	selectedEvents.forEach((selectedEvent) => {
		selectedEvent?.event_promos?.forEach((promo) => {
			promoOptions.push({
				label: promo.promo_name,
				value: promo.promo_id,
				event_id: selectedEvent.event_id,
				...promo,
			});
		});
	});
	return promoOptions;
};
