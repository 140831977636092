import React from "react";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate, useLocation } from "react-router-dom";
import {
	protectedRoutes,
	protectedLoginRoutes,
} from "../../../containers/common/Routes";
import "./Header.scss";

const Breadcrumbs = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const pathnames = pathname.split("/").filter(Boolean);
	return (
		<MUIBreadcrumbs
			className="breadcrumb-container"
			aria-label="breadcrumb"
		>
			{pathnames.map((path, index) => {
				const isLast = index === pathnames.length - 1;
				let currentRoute = protectedRoutes?.find((pRoute) => {
					return pRoute.breadcrumbPath === path;
				});
				if (_.isEmpty(currentRoute)) {
					currentRoute = protectedLoginRoutes?.find((pRoute) => {
						return pRoute.breadcrumbPath === path;
					});
				}
				return isLast ? (
					<Typography
						className="active"
						key={currentRoute?.key}
						variant="span"
						component="span"
					>
						{currentRoute?.title}
					</Typography>
				) : (
					<Link
						key={currentRoute?.key}
						onClick={() => navigate(currentRoute?.path)}
					>
						{currentRoute?.title}
					</Link>
				);
			})}
		</MUIBreadcrumbs>
	);
};

export default Breadcrumbs;
