import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { saveAs } from "file-saver";
import { API, ExcelAPI } from "../../../utils/axios/index";
import { DISCOUNT_LEVELS } from "../../../constants/Constants";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import {
	requestStart,
	requestComplete,
	requestFail,
	toastError,
	toggleLengthyOpLoader,
	requestFailNoLoader,
} from "../global/global";

const initialState = {
	promoList: {},
	activePromoId: null,
	promoDetails: {},
	validOffers: {},
	optValidOffers: {},
	maxPromoStep: null,
	aggregateMetrics: {},
	simulationMetrics: {},
	baselineLyMetrics: {},
	simulatedPromoId: null,
	selectedHeroSkus: [],
	copyPromoValidationDetails: [],
	invalidScenarios: {},
	isFirstCopy: false,
	isHeroSelected: false,
};

const promoSlice = createSlice({
	name: "event",
	initialState,
	reducers: {
		setPromoList(state, action) {
			state.promoList = action.payload;
		},
		setActivePromoId(state, action) {
			state.activePromoId = action.payload;
		},
		setMaxPromoStep(state, action) {
			state.maxPromoStep = action.payload;
		},
		setPromoDetail(state, action) {
			state.promoDetails = action.payload;
		},
		setValidOffers(state, action) {
			state.validOffers = action.payload;
		},
		setOptimizationValidOffers(state, action) {
			state.optValidOffers = action.payload;
		},
		setAggregateMetrics(state, action) {
			state.aggregateMetrics = action.payload;
		},
		setSimulationMetrics(state, action) {
			state.simulationMetrics = action.payload;
		},
		setBaselineLyMetrics(state, action) {
			state.baselineLyMetrics = action.payload;
		},
		setSimulatedPromoId(state, action) {
			state.simulatedPromoId = action.payload;
		},
		setSelectedHeroSkus(state, action) {
			state.selectedHeroSkus = action.payload;
		},
		setValidatedCopyPromosDetails(state, action) {
			state.copyPromoValidationDetails = action.payload;
		},
		setIsAllScenarionValid(state, action) {
			state.invalidScenarios = action.payload;
		},
		setIsFirstCopySimulation(state, action) {
			state.isFirstCopy = action.payload;
		},
		setIsHeroSelected(state, action) {
			state.isHeroSelected = action.payload;
		},
	},
});

export const {
	setPromoList,
	setActivePromoId,
	setMaxPromoStep,
	setPromoDetail,
	setValidOffers,
	setAggregateMetrics,
	setSimulationMetrics,
	setBaselineLyMetrics,
	setSimulatedPromoId,
	setSelectedHeroSkus,
	setOptimizationValidOffers,
	setValidatedCopyPromosDetails,
	setIsAllScenarionValid,
	setIsFirstCopySimulation,
	setIsHeroSelected,
} = promoSlice.actions;
export default promoSlice.reducer;

export const promoActions =
	({ payload, dateRange = "ty", screenName }) =>
	(dispatch, getState) => {
		if (payload.promo_target && !_.isEmpty(payload.promo_target)) {
			dispatch(toggleLengthyOpLoader(true));
		} else {
			dispatch(requestStart());
		}

		return API.post("/promos", payload)
			.then((response) => {
				if (
					payload.action === "get" &&
					(response.status === 200 || response.status === true)
				) {
					if (response.data) {
						if (_.isEmpty(response.data?.data)) {
							dispatch(
								toastError(
									"There is no data for the selected filters"
								)
							);
						}
						const { data } = response.data;
						let formattedData = [...data];
						if (screenName == "decision-dashboard") {
							formattedData = data.filter(
								(promo) => promo.status == 4
							);
						}
						dispatch(
							setPromoList({
								...getState().promo.promoList,
								[dateRange]: formattedData,
							})
						);
						dispatch(requestComplete());
					}
					return true;
				} else if (
					(payload.action === "create" ||
						payload.action === "edit") &&
					(response.status === 200 || response.status === true)
				) {
					if (response.data.status === false) {
						let message = "Something went wrong!";
						message = response.data.message;
						dispatch(requestFail(message));
						return false;
					}
					// Recognize optimization and set lengthyOpLoader
					if (
						payload.promo_target &&
						!_.isEmpty(payload.promo_target)
					) {
						// Wait for SSE
					} else {
						const { data } = response.data;
						dispatch(setActivePromoId(data.promo_id));
						dispatch(requestComplete());
						return data.promo_id;
					}
				} else if (
					(payload.action === "delete" ||
						payload.action === "copy") &&
					(response.status === 200 || response.status === true)
				) {
					const { message } = response.data;
					dispatch(requestComplete(message));
					return true;
				} else if (
					payload.action === "finalize" &&
					(response.status === 200 || response.status === true)
				) {
					const { data } = response.data;
					if (!_.isEmpty(data.promo_ids?.conflicting_promos)) {
						let conflictDetails =
							data.promo_ids?.conflicting_promos;
						dispatch(requestComplete());
						return {
							status: false,
							conflictDetails,
						};
					}
					dispatch(requestComplete());
					return {
						status: true,
					};
				}
			})
			.catch((error) => {
				console.log(error);
				const errorMessage = setErrorMessage(error);
				if (payload.promo_target && !_.isEmpty(payload.promo_target)) {
					dispatch(toggleLengthyOpLoader(false));
					dispatch(requestFailNoLoader(errorMessage));
					return false;
				} else {
					if (payload.action === "get") {
						const data = [];
						let formattedData = [...data];
						if (screenName == "decision-dashboard") {
							formattedData = data.filter(
								(promo) => promo.status == 4
							);
						}
						dispatch(
							setPromoList({
								...getState().promo.promoList,
								[dateRange]: formattedData,
							})
						);
					}
					dispatch(requestFail(errorMessage));
					return false;
				}
			});
	};

export const getPromoDetail =
	({ promo_id, section }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.get(`/promos?promo=${promo_id}&section=${section}`)
			.then((response) => {
				if (response.status === 200 || response.status === true) {
					let { data } = response.data;

					if (section === "simulation") {
						let temp = data.copied_scenario ? true : false;
						dispatch(setIsFirstCopySimulation(temp));
					}

					if (section === "product") {
						let temp = data.hero_selected ? true : false;
						dispatch(setIsHeroSelected(temp));
					}

					const promoDetailCurr = _.cloneDeep(
						getState().promo.promoDetails
					);
					if (promoDetailCurr.promo_id === data.promo_id) {
						data = {
							...promoDetailCurr,
							...data,
						};
					}

					dispatch(setPromoDetail(data));
					dispatch(requestComplete());
					return data;
				}
			})
			.catch((error) => {
				console.log(error);
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
				return false;
			});
	};

export const getBaselineLyMetrics = (promo_id) => (dispatch, getState) => {
	dispatch(requestStart());
	return API.get(`/ly-targets?promo=${promo_id}`)
		.then((response) => {
			if (response.status === 200 || response.status === true) {
				let { data } = response.data;
				dispatch(setBaselineLyMetrics(data));
				dispatch(requestComplete());
				return data;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
			return false;
		});
};

export const getBaselineLyMetricsForEcom =
	(promo_id) => (dispatch, getState) => {
		dispatch(requestStart());
		return API.get(`/ly-targets-ecom?promo=${promo_id}`)
			.then((response) => {
				if (response.status === 200 || response.status === true) {
					let { data } = response.data;
					dispatch(setBaselineLyMetrics(data));
					dispatch(requestComplete());
					return data;
				}
			})
			.catch((error) => {
				console.log(error);
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
				return false;
			});
	};

export const getOptimizationValidOffers =
	({ payload, promo_id }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.post("/valid-offers", payload)
			.then((response) => {
				if (response.status === 200 || response.status === true) {
					const { data } = response.data;
					dispatch(
						setOptimizationValidOffers({
							...getState().promo.optValidOffers,
							[promo_id]: data?.[promo_id],
						})
					);

					dispatch(requestComplete());
					return data;
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
				return false;
			});
	};

export const getValidOffers =
	({ payload, promo_id, hierarchy_id = null }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.post("/valid-offers", payload)
			.then((response) => {
				if (response.status === 200 || response.status === true) {
					const { data } = response.data;
					if (hierarchy_id) {
						dispatch(
							setValidOffers({
								...getState().promo.validOffers,
								[promo_id]: {
									...getState().promo.validOffers?.[promo_id],
									[hierarchy_id]:
										hierarchy_id === DISCOUNT_LEVELS.overall
											? data?.[promo_id]
											: data,
								},
							})
						);
					}
					dispatch(requestComplete());
					return data;
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
				return false;
			});
	};

export const lockPromo = (payload) => (dispatch) => {
	dispatch(requestStart());
	//change api end point
	return API.put("/promos/lock", payload)
		.then((response) => {
			if (response.status === 200 || response.status === true) {
				const { data } = response.data;
				// dispatch(setEventDetails(data));
				dispatch(requestComplete());

				return data;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const simulate =
	({ payload }) =>
	(dispatch, getState) => {
		dispatch(toggleLengthyOpLoader(true));
		API.post(`/simulate`, payload)
			.then((response) => {
				if (
					response.data &&
					(response.data.status === 200 ||
						response.data.status === true)
				) {
					// Wait for SSE
					// dispatch(requestComplete());
				}
			})
			.catch((error) => {
				dispatch(toggleLengthyOpLoader(false));
				const errorMessage = setErrorMessage(error);
				dispatch(requestFailNoLoader(errorMessage));
			});
	};

export const getSimulationMetrics =
	({ payload, aggregation }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.post(`/scenario-metrics`, payload)
			.then((response) => {
				if (
					response.data &&
					(response.data.status === 200 ||
						response.data.status === true)
				) {
					const { data } = response.data;
					const simulationMetrics = _.cloneDeep(
						getState().promo.simulationMetrics
					);
					dispatch(
						setSimulationMetrics({
							...simulationMetrics,
							[aggregation]: data,
						})
					);
					dispatch(requestComplete());
					return data;
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
			});
	};

export const downloadSimulationMetrics =
	({ payload, reportName }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		ExcelAPI.post(`/reports/scenario`, payload)
			.then((response) => {
				// const blob = new Blob([response.data], {
				// 	type: "text/csv;charset=utf-8",
				// });
				const blob = new Blob([response.data], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
				});
				saveAs(blob, reportName);
				dispatch(requestComplete());
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
			});
	};

export const overrideIaForecast = (payload) => (dispatch, getState) => {
	dispatch(requestStart());
	//change api end point
	return API.post("/promo/override-metrics", payload)
		.then((response) => {
			if (response.status === 200 || response.status === true) {
				const { data } = response.data;
				// Set the overridden value in scenarioMetrics redux at overall level
				// check for scenario id and is_overridden = true
				const simulationMetricsTemp = _.cloneDeep(
					getState().promo.simulationMetrics
				);
				if (!_.isEmpty(simulationMetricsTemp?.["overall"])) {
					const activeIndex = simulationMetricsTemp?.[
						"overall"
					]?.[0]?.scenario_data.findIndex(
						(scenario) =>
							scenario.scenario_id === data.scenario_id &&
							scenario.is_overridden
					);
					if (activeIndex !== -1) {
						simulationMetricsTemp["overall"][0].scenario_data[
							activeIndex
						] = {
							...data,
							is_overridden: 1,
						};
					} else {
						const existingScenarioIndex = simulationMetricsTemp?.[
							"overall"
						]?.[0]?.scenario_data.findIndex(
							(scenario) =>
								scenario.scenario_id === data.scenario_id &&
								!scenario.is_overridden
						);
						simulationMetricsTemp?.[
							"overall"
						]?.[0]?.scenario_data.splice(
							existingScenarioIndex + 1,
							0,
							{
								...data,
								is_overridden: 1,
							}
						);
					}
					simulationMetricsTemp[
						"overall"
					][0].last_approved_scenario_id =
						simulationMetricsTemp?.[
							"overall"
						]?.[0].last_approved_scenario_id;
					simulationMetricsTemp["overall"][0].is_overridden_scenario =
						simulationMetricsTemp?.[
							"overall"
						]?.[0].is_overridden_scenario;
				}
				dispatch(setSimulationMetrics(simulationMetricsTemp));
				dispatch(requestComplete());
				return data;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const approveScenario =
	({ payload }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.post(`/approve-scenario`, payload)
			.then((response) => {
				if (
					response.data &&
					(response.data.status === 200 ||
						response.data.status === true)
				) {
					// Wait for SSE
					dispatch(requestComplete());
					return true;
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
				return false;
			});
	};

export const getModelData =
	({ payload, aggregation, screen }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.post(`/model-data`, payload)
			.then((response) => {
				if (
					response.data &&
					(response.data.status === 200 ||
						response.data.status === true)
				) {
					const { data } = response.data;

					// If Using to fetch aggregate metrics
					if (aggregation) {
						const currentAggregateMetrics = _.cloneDeep(
							getState().promo.aggregateMetrics
						);
						dispatch(
							setAggregateMetrics({
								...currentAggregateMetrics,
								[screen]: {
									...currentAggregateMetrics?.[screen],
									[aggregation]: data,
								},
							})
						);
					}

					dispatch(requestComplete());
					return data || {};
				} else {
					dispatch(requestFail());
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
			});
	};

export const downloadPromosTableData = (payload) => (dispatch) => {
	dispatch(requestStart());
	return ExcelAPI.post("/reports/get-promo/download", payload)
		.then((response) => {
			// const blob = new Blob([response.data], {
			// 	type: "text/csv;charset=utf-8",
			// });
			const blob = new Blob([response.data], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
			});
			saveAs(blob, payload.report_name);
			dispatch(requestComplete());
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const downloadPromolevelChange = (payload) => (dispatch) => {
	dispatch(requestStart());
	return ExcelAPI.post("/reports/event-lock-audit/promo", payload)
		.then((response) => {
			// const blob = new Blob([response.data], {
			// 	type: "text/csv;charset=utf-8",
			// });
			const blob = new Blob([response.data], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
			});
			saveAs(blob, payload.report_name);
			dispatch(requestComplete());
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const downloadSkuLevelChange = (payload) => (dispatch) => {
	dispatch(requestStart());
	return ExcelAPI.post("/reports/event-lock-audit/sku", payload)
		.then((response) => {
			// const blob = new Blob([response.data], {
			// 	type: "text/csv;charset=utf-8",
			// });
			const blob = new Blob([response.data], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
			});
			saveAs(blob, payload.report_name);
			dispatch(requestComplete());
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};
export const validateCopyPromos = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.post("/invalid-promo-products", payload)
		.then((response) => {
			dispatch(setValidatedCopyPromosDetails(response.data.data));
			dispatch(requestComplete());
			if (!_.isEmpty(response.data.data)) {
				return false;
			}
			return true;
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const copyPromosToEvent = (payload) => (dispatch) => {
	dispatch(requestStart());

	return API.post("/promos/copy", payload)
		.then((response) => {
			// dispatch(setValidatedCopyPromosDetails(response.data.data));
			dispatch(requestComplete());
			// if(!_.isEmpty(response.data.data)){
			// 	return true
			// }
			// return false;

			return response.data.data;
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const isInvalidCopySimulation = (val) => (dispatch, getState) => {
	// const temp = getState().promo.invalidScenarios;

	// if(!temp && val) {
	dispatch(setIsAllScenarionValid(val));
	// }
};

export const updatePromoNameHero = (payload) => (dispatch) => {
	dispatch(requestStart());

	return API.post("/promo/update-name-hero", payload)
		.then((response) => {
			if (
				response.data &&
				(response.data.status === 200 || response.data.status === true)
			) {
				dispatch(requestComplete());
				return true;
			} else {
				dispatch(requestFail());
				return false;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
			return false;
		});
};
